/// <reference types="gtag.js" />

window.dataLayer = window.dataLayer || [];

const gtag: Gtag.Gtag = function() {
  window.dataLayer.push(arguments); // eslint-disable-line prefer-rest-params -- Follow standard Google example
};
window.gtag = gtag;

/*
 * Set default consent to 'denied'; see https://developers.google.com/tag-platform/security/guides/consent
 * For a full list of configuration options, see https://developers.google.com/analytics/devguides/collection/ga4/reference/config
 */
gtag('consent', 'default', {
  ad_storage: 'denied',
  ad_user_data: 'denied',
  ad_personalization: 'denied',
});

gtag('set', 'ads_data_redaction', true);
gtag('js', new Date());
gtag('config', document.head.dataset.analyticsId!, {
  allow_ad_personalization_signals: false,
  allow_google_signals: false,
  cookie_flags: 'SameSite=Lax;Secure',
});

export {}; // required to make TypeScript happy
declare global {
  interface Window {
    dataLayer: Array<unknown>;
  }
}
